if (module.hot) {
  module.hot.accept();
}

const burger = document.querySelector('.hamburger');
const header = document.querySelector('.mobile-menu');

const runMenu = () => {
  burger.classList.toggle('is-active');
  header.classList.toggle('menu-opened');
};


burger.addEventListener('click', runMenu, false);

